.body {
  font-family: 'Roboto', sans-serif;
}

.t-wrapper {
  height: 100%;
  margin: 0 auto;
  max-width: 1360px;
  // background: @dark;
  padding: 20px;

  @media @l {
    max-width: 1100px;
  }

  @media @sm {
    padding: 0 20px;
  }

  @media @s {
    padding: 0 20px;
  }
}

.ant-btn {
  box-shadow: none !important;
  border: none !important;

  &.ant-btn-primary {
    background-color: @gold;
    transition: all linear 0.3s;

    span {
      transition: all linear 0.3s;
    }

    &:hover {
      background-color: @gold;

      span {
        color: @white;
        transition: all linear 0.3s;
      }
    }
  }

  &.ant-btn-default {
    &:hover {
      span {
        color: @white;
        transition: all linear 0.3s;
      }
    }
  }
}

.link-hover {
  position: relative;
  &::before {
    position: absolute;
    bottom: -3px;
    content: '';
    display: block;
    // left: 50%;
    width: 100%;
    height: 2px;
    background-color: @white;
    transform: scale(0);
    transition: transform 0.5s ease; /* Плавный переход изменения трансформации */
    transform-origin: center;
  }

  &:hover {
    &::before {
      transform: scaleX(1);
    }
  }
}

.title-wrapper {
  &::before {
    content: '';
    display: block;
    height: 6px;
    width: 90px;
    background-color: @gold;
    margin-bottom: 40px;
  }
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  text-transform: uppercase;
  color: @white;

  @media @s {
    font-size: 28px;
    line-height: 36px;
  }
}
