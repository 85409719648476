.t-footer {
  background-color: @gold;
  position: relative;
  height: 160px;

  @media @s {
    height: auto;
  }

  &__unp {
    color: #2f343a;
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 10%;
    left: 2%;

    @media @xs {
      bottom: 2%;
      top: auto;
    }
  }

  & > .t-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 70%;

    @media @sm {
      padding: 30px 20px;
      max-width: 100%;
    }

    @media @s {
      flex-direction: column;
    }
  }

  &__logo {
    width: 160px;
    height: 50px;

    @media @sm {
      // width: 100px;
      object-fit: contain;
    }
  }

  &__address {
    display: flex;
    // flex-direction: column;
    align-items: center;

    @media @s {
      width: 100%;
      justify-content: center;
    }

    @media @xs {
      flex-direction: column;
    }

    .address-line {
      width: 1px;
      height: 50px;
      background-color: @white;
      margin: 0 30px 0 30px;

      @media @xs {
        display: none;
      }
    }

    .address-wrapper {
      display: flex;
      flex-direction: column;
      // margin-bottom: 10px;

      @media @xs {
        margin-top: 20px;
        align-items: center;
      }

      // .email {
      //   font-style: normal;
      //   font-weight: 400;
      //   font-size: 14px;
      //   line-height: 16px;
      //   color: @white;
      // }
    }

    & .address {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: @white;
      margin-bottom: 20px;
      transition: all 0.5s linear;

      @media @s {
        font-size: 24px;
        line-height: 28px;
        text-align: center;
      }
    }

    & .email {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: @white;
      transition: all 0.5s linear;

      @media @s {
        font-size: 24px;
        line-height: 28px;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    @media @s {
      align-items: center;
      margin-bottom: 20px;
    }
  }

  &__phone {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &-title {
      color: #2f343a;
      font-size: 14px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 15px;
    }

    & > .phone {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: @white;
      margin-bottom: 10px;
      transition: all 0.5s linear;

      @media @s {
        font-size: 24px;
        line-height: 28px;
      }

      &:hover {
        transform: scale(1.1);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__author {
    display: flex;
    flex-direction: column;

    @media @sm {
      flex-direction: row;
      padding: 0 20px;
    }

    @media @s {
      justify-content: center;
    }

    & > .author {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: @dark;
      transition: all 0.5s linear;
      margin-bottom: 5px;

      @media @sm {
        margin-inline-end: 5px;
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
