@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import './reset.less';
@import './layout.less';
@import '../components/Header/index.less';
@import './homePage.less';
@import '../components/Slider/index.less';
@import '../components/Services/index.less';
@import '../components/Footer/index.less';
@import '../components/HomeComponent/index.less';
@import '../components/Calc/index.less';
@import '../components/Info/info.less';
@import '../components/MapComponent/index.less';

/* Media queries */
@xs: ~'(max-width: 480px)';
@s: ~'(max-width: 640px)';
@sm: ~'(max-width: 768px)';
@mm: ~'(max-width: 991px)';
@m: ~'(max-width: 1200px)';
@l: ~'(max-width: 1366px)';
@xl: ~'(max-width: 1440px)';
@xxl: ~'(max-width: 1600px)';

@black: #26292b;
@white: #ffffff;
@light: #a2b2ee;
@gold: #c8a35f;
@dark: #2e3239;
@dark2: #393f47;

@dark1: #1b2223;
// @dark2: #3a4f50;
@dark3: #0ef6cc // @black: #131421;;
// @white: #fff;
// @light: #f8f8f8;
// @gold: #c8a35f;

@primary-color: #131421;@link-color: #131421;@white: #fff;@black: #131421;@blue: #178CFF;@grey: #6C7380;@normal-color: #d9d9d9;@heading-color: @blue;@text-color: @grey;@layout-body-background: linear-gradient(0deg, #F0F9F5, #F0F9F5), #F0F2F5;@layout-header-background: #202123;@input-color: @blue;@btn-text-shadow: none;@border-radius-base: 8px;@font-family: Rubik, sans-serif;