.t-home {
  &__back {
    position: relative;
    min-height: calc(100vh - 145px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media @sm {
      min-height: calc(100vh - 105px);
      // margin-bottom: 50px;
    }

    @media @s {
      // padding: 40px 20px;
      min-height: auto;
    }

    &-info {
      position: relative;
      // height: 100%;
      // min-height: 620px;
      // width: calc(920 * 100 / 100vw);
      // max-width: 920px;
      border: 1px solid @gold;
      padding: 40px 70px;
      border-radius: 10px;

      @media @l {
        max-width: 1100px;
      }

      @media @sm {
        max-width: 690px;
        padding: 20px 40px;
      }

      @media @s {
        padding: 20px 10px;
      }

      & .title {
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 75px;
        text-align: center;
        text-transform: uppercase;
        color: @white;
        margin-bottom: 35px;

        @media @sm {
          font-size: 34px;
          margin-bottom: 25px;
        }

        @media @s {
          font-size: 24px;
          line-height: 36px;
        }

        @media @xs {
          color: @dark;
          margin-bottom: 5px;
        }
      }

      & .subtitle {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 28px;
        text-align: start;
        color: @white;
        text-transform: uppercase;
        margin: 0 auto;
        margin-bottom: 50px;
        @media @sm {
          width: 100%;
        }

        @media @m {
          text-align: center;
        }

        @media @s {
          font-size: 14px;
          line-height: 24px;
        }
        @media @xs {
          color: @dark;
          margin-bottom: 5px;
        }
      }
    }

    // &::before {
    //   content: '';
    //   position: absolute;
    //   bottom: 5%;
    //   width: 30px;
    //   height: 30px;
    //   background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.8' clip-path='url(%23clip0_4_31)'%3E%3Cpath d='M14.9767 0C10.1109 0 6.15235 3.95851 6.15235 8.82431V21.1757C6.15235 26.0415 10.1109 30 14.9767 30C19.8425 30 23.801 26.0415 23.801 21.1757V8.82431C23.801 3.95851 19.8425 0 14.9767 0ZM22.001 21.1757C22.001 25.0488 18.8498 28.2001 14.9767 28.2001C11.1035 28.2001 7.95228 25.0488 7.95228 21.1757V8.82431C7.95228 4.95117 11.1035 1.79993 14.9767 1.79993C18.8498 1.79993 22.001 4.95117 22.001 8.82431V21.1757Z' fill='%23A2B2EE'/%3E%3Cpath d='M14.9769 5.9679C14.4798 5.9679 14.0769 6.37073 14.0769 6.86786V11.0102C14.0769 11.5071 14.4798 11.9101 14.9769 11.9101C15.4738 11.9101 15.8768 11.5071 15.8768 11.0102V6.86786C15.8768 6.37073 15.474 5.9679 14.9769 5.9679Z' fill='%23A2B2EE'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4_31'%3E%3Crect width='30' height='30' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    //   background-position: center;
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   z-index: 2;
    //   animation: back 2s infinite linear;

    //   @media @s {
    //     display: none;
    //   }
    // }

    &-icons {
      max-width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media @m {
        max-width: 100%;
      }

      @media @xs {
        flex-direction: column;
        align-items: flex-start;
      }

      & .icon {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;

        @media @xs {
          margin-bottom: 10px;
        }

        & .text {
          color: #fff;
          text-align: center;
          font-size: 18px;
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
          margin-inline-start: 10px;
        }
      }
    }
  }
  & .back {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
  }
}
