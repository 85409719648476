.t-home__back {
  position: relative;
  overflow: hidden;
  width: 100%;

  &-img {
    width: 100vw !important;
    height: calc(100vh - 120px) !important;
    object-fit: cover;

    @media @s {
      height: calc(100vh - 410px) !important;
    }
  }

  &-info {
    position: absolute;
    right: 40%;
    background-color: @dark;
    background: @white;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(5px) opacity(90%);
    border: 1px solid @gold;

    @media @xl {
      right: 30%;
    }

    @media @m {
      right: 0;
      left: 0;
      top: 50%;
      transform: translate(5%, -50%);
    }

    @media @s {
      top: 25%;
      transform: none;
      padding: 20px 16px;
      margin: 0 16px;
    }

    @media @xs {
      top: 10%;
      backdrop-filter: blur(5px) opacity(40%);
    }
  }

  .logo-item {
    position: absolute;
  }
}

.t-home__wrapperLogos {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  // background-color: red;

  @media @s {
    display: none;
  }
}
