.t-services {
  margin-top: 120px;
  background-color: @dark2;
  padding-bottom: 50px;

  @media @sm {
    margin-top: 50px;
  }

  @media @s {
    padding: 20px 0;
  }

  &__header {
    margin-bottom: 60px;
    &::before {
      content: '';
      display: block;
      height: 6px;
      width: 90px;
      background-color: @gold;
      margin-bottom: 40px;
    }
  }

  &__desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 90px;

    @media @l {
      justify-content: space-between;
    }

    @media @sm {
      flex-direction: column;
      margin-bottom: 20px;
    }

    .desc {
      font-size: 18px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      color: @white;
      width: 30%;

      @media @l {
        width: 100%;
      }

      @media @sm {
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    text-transform: uppercase;
    color: @white;

    @media @sm {
      text-align: center;
    }

    @media @s {
      font-size: 28px;
      line-height: 36px;
    }
  }

  &__items {
    width: 70%;

    @media @l {
      width: 100%;
    }

    @media @s {
      flex-direction: column;

      & > div {
        width: 100%;
        flex: 1;
        max-width: 100%;
      }
    }
  }

  &__item {
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: @gold;
    border-radius: 16px;
    transition: all 0.5s linear;
    cursor: pointer;

    @media @sm {
      height: 110px;
    }

    & > .icon {
      // margin-bottom: 40px;
      width: 24px;
      height: 24px;
      object-fit: contain;
      background-color: @white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-bottom: 15px;

      & > span {
        width: 24px;
      }

      @media @sm {
        margin-bottom: 20px;
      }
    }

    & > .title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: @white;

      @media @sm {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
      }
    }

    &:hover {
      background-color: @gold;
    }
  }

  &__footer-desc {
    display: block;
    color: @white;
    font-size: 18px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    text-align: end;
  }
}
