.t-home__slider {
  height: 560px;
  background-color: @dark;
  position: relative;
  @media @sm {
    padding: 40px 0;
  }

  &-wrapper {
    // width: 100%;
    // max-width: 1360px;
    // height: 100%;
    // margin: 0 auto;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // position: relative;
    // z-index: 1;

    // @media @l {
    //   max-width: 1100px;
    // }

    // @media @sm {
    //   max-width: 690px;
    //   flex-direction: column;
    //   justify-content: center;
    // }

    // @media @s {
    //   padding: 0 20px;
    // }
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    @media @sm {
      top: 40px;
      display: block;
    }

    // & .swiper {
    //   @media @m {
    //     padding-inline: 24px;
    //     margin-inline: -24px;
    //   }
    // }
  }

  &::before {
    content: '';
    background-color: @dark2;
    width: 30%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    @media @sm {
      width: 35%;
    }

    @media @s {
      width: 100%;
    }
  }

  &::after {
    content: '';
    background-color: @dark2;
    width: 30%;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    @media @sm {
      width: 35%;
    }
  }

  &-btns {
    display: flex;
    flex-direction: column;
    height: 60%;
    justify-content: space-between;

    @media @sm {
      width: 100%;
      flex-direction: row-reverse;
      height: auto;
    }

    @media @s {
      justify-content: space-between;
    }

    // .btn-next {
    //   display: flex;
    //   align-items: center;

    //   @media @sm {
    //     padding: 0;
    //   }

    //   & > span {
    //     font-style: normal;
    //     font-weight: 500;
    //     font-size: 16px;
    //     text-align: center;
    //     text-transform: uppercase;
    //     color: @gold;
    //     margin-inline-end: 30px;

    //     @media @s {
    //       margin-inline-end: 0;
    //     }
    //   }

    //   @media @s {
    //     & > .anticon {
    //       & > svg {
    //         width: 50px;
    //       }
    //     }
    //   }
    // }

    // .btn-back {
    //   display: flex;
    //   align-items: center;

    //   @media @sm {
    //     padding: 0;
    //   }

    //   & > span {
    //     font-style: normal;
    //     font-weight: 500;
    //     font-size: 16px;
    //     text-align: center;
    //     text-transform: uppercase;
    //     color: @gold;
    //     margin-inline-start: 30px;

    //     @media @s {
    //       margin-inline-start: 0;
    //       margin-inline-end: 8px;
    //     }
    //   }

    //   @media @s {
    //     & > .anticon {
    //       & > svg {
    //         width: 50px;
    //       }
    //     }
    //   }
    // }

    .btn-next {
      width: 50px;
      min-width: 50px;
      height: 50px;
      border-radius: 50%;
      right: 5%;
      background-color: @gold;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z' fill='%23181818'/%3e%3c/svg%3e ");
      background-repeat: no-repeat;
      background-position: center;

      @media @sm {
        right: auto;
      }

      @media @s {
        top: 80%;
        right: 10%;
      }

      @media @xs {
        top: 90%;
      }
    }

    .btn-back {
      width: 50px;
      min-width: 50px;
      height: 50px;
      left: 5%;
      border-radius: 50%;
      background-color: @gold;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12 4L13.41 5.41L7.83 11H20V13H7.83L13.41 18.59L12 20L4 12L12 4Z' fill='%23181818'/%3e%3c/svg%3e ");
      background-repeat: no-repeat;
      background-position: center;

      @media @sm {
        left: 0;
        right: auto;
      }

      @media @s {
        top: 80%;
        left: 10%;
      }

      @media @xs {
        top: 90%;
      }
    }
  }

  &-info {
    display: flex;
    justify-content: center;
    align-items: center;

    @media @sm {
      flex-direction: column-reverse;
      margin-bottom: 40px;
    }

    @media @s {
      padding: 0 16px;
    }

    .slider-img {
      width: 445px;
      height: 260px;
      object-fit: contain;
      transition: all 0.3s linear;

      @media @sm {
        width: 100%;
        height: 150px;
      }
    }

    .info {
      .btn {
        width: 220px;
        height: 55px;
        background-color: @gold;
        border: none;

        @media @sm {
          width: 180px;
          height: 45px;
        }

        @media @s {
          width: 100%;
        }

        & > span {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 25px;
          text-align: center;
          text-transform: uppercase;
          color: @white;
        }
      }

      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        text-transform: uppercase;
        color: @white;
        margin-bottom: 30px;
        // width: 60%;
        // transition: all 0.3s linear;

        @media @sm {
          width: 100%;
          font-size: 35px;
          text-align: center;
          margin-top: 20px;
          margin-bottom: 10px;
        }

        @media @s {
          font-size: 28px;
          line-height: 36px;
        }

        &::before {
          content: '';
          display: block;
          height: 6px;
          width: 90px;
          background-color: @gold;
          margin-bottom: 40px;

          @media @sm {
            display: none;
          }
        }
      }

      .desc {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: @white;
        margin-bottom: 50px;
        // width: 60%;
        // transition: all 0.3s linear;

        @media @sm {
          width: 100%;
          margin-bottom: 25px;
        }

        @media @s {
          text-align: center;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}
