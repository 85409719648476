.t-header {
  background-color: @gold;

  & .t-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  &__tel,
  &__email {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    color: @white;
  }

  &__footer {
    background-color: @dark;
    position: sticky;
    top: 0;
    z-index: 20;
    box-shadow: none;
    transition: all linear 0.3s;

    &.active {
      transition: all linear 0.3s;
      box-shadow: 0px 28px 38px 5px rgba(200, 163, 95, 0.2);
    }

    & .t-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;

      @media @sm {
        padding: 30px 20px;
      }

      @media @s {
        padding: 10px 20px;
      }
    }

    &-logo {
      display: flex;
      align-items: center;

      @media @sm {
        // margin-inline-end: 40px;
        justify-content: center;
      }

      @media @s {
        width: 100%;
        // margin-inline-end: 10px;
      }
    }

    &-address {
      padding-inline-start: 30px;
      display: flex;
      align-items: center;
      transition: all linear 0.3s;

      & .title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: @white;
        margin-inline-start: 10px;
        transition: all linear 0.3s;
      }

      @media @sm {
        padding-inline-start: 10px;
      }

      @media @s {
        padding-inline-start: 10px;
      }

      .anticon {
        transition: all linear 0.3s;
      }

      &:hover {
        transition: all linear 0.3s;
        background-color: transparent !important;
        .title {
          color: @white;
        }
        .anticon {
          transform: scale(1.3);
        }
      }
    }

    &-links {
      display: flex;

      & .link-item {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: @white;
        cursor: pointer;
        transition: all linear 0.3s;
        margin-inline-end: 30px;

        @media @sm {
          margin-inline-end: 10px;
        }

        &:last-child {
          margin-inline-end: 0;
        }

        &:hover {
          color: @gold;
          transform: scale(1.2);
        }
      }
    }

    .address {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: @white;
    }

    .address-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  &__logo {
    .t-logo {
      transition: all 0.3s linear;
    }
    &:hover {
      .t-logo {
        transition: all 0.3s linear;
        fill: @white;
      }
    }
    & .logo {
      width: calc(~'160 / 1920 * 100vw');
      height: 50px;
      object-fit: contain;

      @media @sm {
        width: 200px;
      }
    }

    border-right: 1px solid @white;
    padding-inline-end: 30px;

    @media @sm {
      padding-inline-end: 0;
      margin-inline-start: 50px;
      border-right: none;
    }

    @media @s {
      // padding-inline-end: 10px;
    }
  }

  &__mobile {
    padding: 20px 22px;
    position: relative;
    z-index: 200;
    &-drawer.ant-drawer-content {
      background: @dark;
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    & > .link-item {
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 24px;
      color: @white;
      cursor: pointer;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__burger {
    display: none;
    transition: 0.3s ease;

    &-btn {
      opacity: 1;
      transition: all 0.3s linear;
      position: relative;

      &.open {
        z-index: 20;
      }
    }

    &.open {
      display: block;
      top: 0;
      right: 0;
      position: absolute;

      & .t-header__mobile {
        margin-top: 40px;
      }
    }

    &-bg {
      position: fixed;
      width: 0;
      height: 0;
      top: 0;
      right: 0;
      z-index: 3;
      background: @gold;
      border-radius: 50%;
      // transform: translate(0, 0);
      transition: all 0.3s ease;

      &.open {
        width: 520px;
        height: 460px;
        top: -100px;
        right: -65%;
        // transform: translate(40%, -22%);
      }
    }

    &-layout {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: opacity 0.3s linear;
      &.open {
        display: block;
        background-color: @dark;
        opacity: 0.9;
        z-index: 2;
        height: 100vh;
        width: 100%;
      }
    }
  }

  &__modal-wrapper {
    & .ant-modal-title {
      display: flex;
      justify-content: center;
    }

    .ant-modal-content {
      background-color: @dark;
    }

    .ant-modal-header {
      background-color: @dark;
    }

    .ant-btn-default {
      background-color: @gold;
    }

    .t-contacts {
      // display: flex;
      padding: 0 50px;

      @media @s {
        padding: 20px 0;
      }

      &__item {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-bottom: 20px;

        @media @s {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
        }

        & > .title {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: @gold;
        }

        & > .link {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: @gold;
        }

        &::before {
          position: absolute;
          bottom: 0;
          content: '';
          display: block;
          bottom: 0;
          // left: 50%;
          width: 100%;
          height: 2px;
          background-color: @gold;
          transform: scale(0);
          transition: transform 0.5s ease; /* Плавный переход изменения трансформации */
          transform-origin: center;
        }

        &:hover {
          &::before {
            transform: scaleX(1);
          }
        }
      }
    }

    .t-contact__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid @gold;
      margin-bottom: 40px;
      padding-bottom: 10px;

      @media @s {
        margin-bottom: 10px;
      }

      & > .title {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        color: @gold;
      }
    }
  }

  &__addresses {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

    @media @s {
      padding: 0 0 40px 0;
    }

    .address {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: @gold;
    }

    .modalLink {
      white-space: normal;
      transition: all 0.3s linear;
      text-align: center;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &__drawer {
    background-color: @dark !important;
  }
}
