.t-info {
  background-color: @dark2;
  padding: 110px 0;

  @media @sm {
    padding: 50px 0;
  }

  .title {
    margin-bottom: 50px;
  }

  .t-wrapper {
    @media @l {
      padding: 0;
    }

    @media @sm {
      padding: 0 20px;
      & > div {
        flex-direction: column;
      }
    }
  }

  &__col {
    @media @sm {
      max-width: 100%;

      .title-wrapper {
        &::before {
          width: 100%;
        }
      }

      .title {
        text-align: center;
      }
    }
  }

  &__desc {
    color: @white;
    font-size: 18px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;

    &-list {
      display: flex;
      flex-direction: column;

      .desc-item {
        color: @white;
        font-size: 18px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
  }
}
