.t-calc {
  margin-bottom: 130px;

  @media @sm {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .t-title {
    margin-bottom: 10px;
  }

  &__inputs {
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .input_group {
      display: flex;
      align-items: center;

      @media @xs {
        flex-direction: column;
      }

      @media @xs {
        width: 100%;
      }

      .arrow {
        margin-inline-start: 15px;

        @media @xs {
          display: none !important;
        }
      }
    }

    @media @xs {
      flex-direction: column;
    }
  }

  &__input {
    width: 220px;
    height: 55px;
    border: 1px solid @gold;
    background-color: transparent;
    color: @white;

    & > .ant-select-selector {
      height: 55px !important;
      align-items: center;
      background-color: transparent !important;
      border: none !important;

      .ant-select-selection-search {
        & > input {
          color: @white !important;
          height: 55px !important;
        }
      }
      .ant-select-selection-placeholder {
        color: @white;
        text-align: center;
        font-size: 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;
      }

      .ant-select-selection-item {
        color: @white;
      }
    }

    @media @sm {
      width: 190px;
    }

    @media @xs {
      width: 100%;
    }

    .ant-input {
      background-color: transparent;
      color: @white;
      &::placeholder {
        color: @white;
        text-align: center;
        font-size: 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    & .ant-input-clear-icon > span > svg > path {
      fill: @white;
    }

    &-number {
      width: 220px;
      height: 55px;
      border: 1px solid @gold;
      background-color: transparent;
      color: @white;
      margin-inline-start: 15px;
      @media @sm {
        width: 190px;
      }
      @media @s {
        margin-inline-start: 0;
        margin-top: 20px;
      }

      @media @xs {
        margin-bottom: 20px;
        width: 100%;
      }

      .ant-input {
        background-color: transparent;
        color: @white;
        &::placeholder {
          color: @white;
          text-align: center;
          font-size: 16px;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          text-transform: uppercase;
        }
      }

      & .ant-input-clear-icon > span > svg > path {
        fill: @white;
      }
    }
  }

  &__total {
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    @media @xs {
      flex-direction: column;
    }
  }

  &__summ {
    color: @white;
    font-size: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;

    &.total {
      font-size: 20px;
      margin-bottom: 5px;
    }

    &.info {
      color: @gold;

      @media @s {
        margin-bottom: 10px;
      }
    }

    @media @xs {
      margin-bottom: 20px;
    }
  }

  &__btn {
    width: 220px;
    height: 55px;
    background: @gold;
    border-radius: 8px;
    color: @white;
    text-align: center;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__map {
    background: rgba(57, 63, 71, 0.3);

    .map-wrapper {
      border-radius: 8px;
      overflow: hidden;

      @media @xs {
        max-width: 100%;
        overflow-x: hidden;
      }
    }
    .t-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      @media @sm {
        flex-direction: column;
      }

      // @media @l {
      //   display: flex;
      //   flex-direction: column;
      // }
    }

    .phone {
      display: flex;
      flex-direction: column;

      @media @sm {
        width: 100%;
        padding: 0 20px;
      }

      &-title {
        color: @gold;
        font-size: 40px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 30px;
        text-align: end;

        @media @l {
          font-size: 30px;
        }

        @media @sm {
          margin-top: 10px;
        }
      }

      &-number {
        color: @white;
        font-size: 40px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;

        @media @l {
          font-size: 30px;
        }

        @media @sm {
          text-align: end;
        }

        &.one,
        &.two {
          &::before {
            position: absolute;
            bottom: 0;
            content: '';
            display: block;
            bottom: 0;
            // left: 50%;
            width: 100%;
            height: 2px;
            background-color: @gold;
            transform: scale(0);
            transition: transform 0.5s ease; /* Плавный переход изменения трансформации */
            transform-origin: center;
          }

          &:hover {
            &::before {
              transform: scaleX(1);
            }
          }
        }
      }

      &-code {
        color: @gold;
      }
    }
  }

  &__radio {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    &-title {
      color: @white;
      font-size: 16px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 5px;
    }

    &-wrapper {
    }

    &-btn {
      background-color: transparent;
      border: 1px solid @gold;

      & > span {
        color: @white;
        font-size: 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
      }

      &.ant-radio-button-wrapper-checked {
        background-color: @gold;
      }
    }
  }
}
