.t-map {
  &__header {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media @xs {
      text-align: center;
    }

    &-title {
      color: @white;
      font-size: 40px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}
